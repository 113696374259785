"use client";

import React, { FC, useEffect, useRef, useState } from "react";
import Heading from "@/components/Heading/Heading";
// @ts-ignore
import Glide from "@glidejs/glide/dist/glide.esm";
import { Brand } from "@/interfaces/brands.interface";
import BrandCard from "./BrandCard";

export interface SectionSliderBrandCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Brand[];
}

const SectionSliderBrandCard: FC<SectionSliderBrandCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "",
  data // PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderRef = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const sliderInstance = useRef(null);

  // console.log('data', data);


  const initializeSlider = () => {
    const OPTIONS: Partial<Glide.Options> = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    if (sliderInstance.current) {
      //@ts-ignore
      // sliderInstance.current.destroy();
    }

    if (sliderRef.current) {
      sliderInstance.current = new Glide(sliderRef.current, OPTIONS);
      //@ts-ignore
      sliderInstance.current.mount();
      setIsShow(true);
    }
  };

  useEffect(() => {
    initializeSlider();

    return () => {
      if (sliderInstance.current) {
        //@ts-ignore
        sliderInstance.current.destroy();
      }
    };
  }, [sliderRef]);

  useEffect(() => {
    initializeSlider();
  }, [data]);

  return (
    <div className={`nc-SectionSliderBrandCard ${className}`}>
      <div ref={sliderRef} className={`flow-root ${isShow ? "" : "invisible"}`}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || ``}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data && data.length > 0 ? (
              //@ts-ignore
              data.map((item, index) => {
                // console.log('item', item);
                return (
                  (
                    <li key={index} className={`glide__slide ${itemClassName}`}>
                      <BrandCard data={item} />
                    </li>
                  )
                )
              })
            ) : (
              <div>No se encontraron marcas</div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderBrandCard;
