import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/DiscoverMoreSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionHero/LandingHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderBrandCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderCategories/SectionSliderCategories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderProductCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/images/banners/landing-banner-one.jpeg");
;
import(/* webpackMode: "eager" */ "/app/src/images/banners/landing-banner-two.jpeg");
