"use client";

import React, { FC, useEffect, useRef, useState } from "react";
import Heading from "@/components/Heading/Heading";
// @ts-ignore
import Glide from "@glidejs/glide/dist/glide.esm";
import ProductCard from "./ProductCard";
import { Product } from "@/interfaces/product.interface";
import { useStore } from "zustand";
import useProductsStore from "@/store/products/products.store";
import { useSearchParams } from "next/navigation";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  categoryId?: string;
  brandId?: string;
  search?: string;
  data?: Product[];
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "",
  categoryId,
  brandId,
  search,
  // data // PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderRef = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const [productsTop, setProductsTop] = useState<Product[]>([]);
  const sliderInstance = useRef(null);

  const searchParams = useSearchParams();

  // console.log('data', data);

  // useEffect(() => {
  //   if (data != undefined) {
  //     setProductsTop(data);

  //     console.log('productsTop',productsTop);
  //   }
  // }, [data]);

  const productsStore = useStore(useProductsStore, (state) => state);

  useEffect(() => {
    const fetchData = async () => {

      const productId = searchParams.get('productId');

      const products = await productsStore.getProductsByParam(
        1,
        10,
        categoryId,
        brandId,
        search
      );
      setProductsTop(products);
    };

    fetchData();
  }, []);

  const initializeSlider = () => {
    const OPTIONS: Partial<Glide.Options> = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    if (sliderInstance.current) {
      //@ts-ignore
      // sliderInstance.current.destroy();
    }

    if (sliderRef.current) {
      sliderInstance.current = new Glide(sliderRef.current, OPTIONS);
      //@ts-ignore
      sliderInstance.current.mount();
      setIsShow(true);
    }
  };

  useEffect(() => {
    initializeSlider();

    return () => {
      if (sliderInstance.current) {
        //@ts-ignore
        sliderInstance.current.destroy();
      }
    };
  }, [sliderRef]);

  useEffect(() => {
    initializeSlider();
  }, [productsTop]);

  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <div ref={sliderRef} className={`flow-root ${isShow ? "" : "invisible"}`}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || ``}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {
              //@ts-ignore
              productsTop.products && productsTop.products.length > 0 ? (
                //@ts-ignore
                productsTop.products.map((item, index) => {
                  // console.log('item', item);
                  return (
                    (
                      <li key={index} className={`glide__slide ${itemClassName}`}>
                        <ProductCard data={item} />
                      </li>
                    )
                  )
                })
              ) : (
                <div>No se encontraron productos asociados</div>
              )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
