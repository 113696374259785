"use client";

import React, { FC, useState } from "react";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { Product, PRODUCTS } from "@/data/data";
import { StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "@/shared/Button/ButtonPrimary";
import ButtonSecondary from "@/shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@/app/headlessui";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import Image from "next/image";
import Link from "next/link";
import NcImage from "@/shared/NcImage/NcImage";

export interface BrandCardProps {
  className?: string;
  data?: any; //Product;
  isLiked?: boolean;
}

const BrandCard: FC<BrandCardProps> = ({
  className = "",
  data, // = PRODUCTS[0],
  isLiked
}) => {
  const [variantActive, setVariantActive] = useState(0);
  const [showModalQuickView, setShowModalQuickView] = useState(false);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const goToProductDetail = () => {

    // ahora tienes un objeto de lectura/escritura
    const current = new URLSearchParams(Array.from(searchParams.entries()));

    // actualizar según sea necesario
    // const value = event.target.value.trim();


    // console.log('data.id, ', data);

    if (!data.producto_id) {
      current.delete("productId");
    } else {
      current.set("productId", data.producto_id.toString() || data.id.toString());
    }

    // convertir a cadena
    const search = current.toString();

    // construir el query
    const query = search ? `?${search}` : "";

    console.log('query', query);

    // redirigir con el nuevo query
    //@ts-ignore
    router.replace(`/product-detail/${query}`);
    // router.replace(`product-detail/${pathname}${query}`, { scroll: false })
  };

  // const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
  //   event.currentTarget.src = '../images/no_image_available.png'; // Cambia esto a la ruta de tu imagen por defecto
  // };

  return (
    <>
      <div
        className={`nc-BrandCard relative flex flex-col bg-transparent ${className} cursor-pointer`}
        // onClick={() => goToProductDetail()}
      >
        {/* <Link href={"/product-detail"} className="absolute inset-0"></Link> */}

        <div className="relative flex-shrink-0 rounded-3xl overflow-hidden z-1 group">
        {/* relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group */}
          {/* <Link href={"/product-detail"} className="block"> */}
          <div className={'flex aspect-w-1 aspect-h-1 w-full h-0'}>
            <Image
              src={data?.logo}
              className="object-contain w-full h-full drop-shadow-xl"
              fill
              sizes="(max-width: 400px) 100vw, (max-width: 1200px) 50vw, 40vw"
              alt="brands"
              onError={(e: any) => {
                e.target.style.display = 'none' // Oculta la imagen si falla la carga
                // O muestra una imagen de respaldo:
                // e.target.src = '/ruta/a/imagen/respaldo.png'
              }}
            />
          </div>

          {/* </Link> */}
          {/* <ProductStatus status={status} />
          <LikeButton liked={isLiked} className="absolute top-3 end-3 z-10" /> */}
          {/* {sizes ? renderSizeList() : renderGroupButtons()} */}
        </div>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          {/* {renderVariants()} */}
          <div>
            {/* <h2 className="nc-BrandCard__title text-base font-semibold transition-colors">
              {data?.nombre || data?.name}
            </h2> */}
            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
              {/* {data?.descripcion.split(',')[0].trim()} */}
            </p>
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default BrandCard;
