import { IUser } from '@/interfaces/user.interface';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import categories from '@/api/categories';
import { IBanner } from '@/interfaces/banner.interface';
import banner from '@/api/banner';

interface BannerState {
    getBanners: () => Promise<IBanner[]>;
    banners: IBanner[] | [];
}

const useBannerStore = create<BannerState>()(
    persist(
        (set) => ({
            banners: [],
            getBanners: async () => {
                // list all banners
                const response = await banner.listBanner();

                console.log('banners', response);

                set((state) => ({
                    ...state,
                    banners: response.data
                }));
                return response.data;
            },
        }),
        {
            name: 'banners',
        }
    )
);

export default useBannerStore;