import { AxiosResponse } from 'axios';
import client from './client';

import { setCookie } from 'cookies-next';
import { IBanner } from '@/interfaces/banner.interface';

const ENDPOINT = "banners";

const listBanner = (): Promise<AxiosResponse<IBanner[]>> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}/list`;

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};

export default {
    listBanner
};
